import { LazyLoad } from "./modules/lazy-load.min.js";
import { Swipers } from "./modules/swipers.min.js";
import { JustifiedGallery } from "./modules/justified-gallery.min.js";

// Components & Pages
import { Header } from "./modules/header.min.js";
import { Home } from "./modules/home.min.js";
import { About } from "./modules/about.min.js";
import { Products } from "./modules/products.min.js";
import { ProductPage } from "./modules/product-page.min.js";
import { News } from "./modules/news.min.js";
import { SingleNews } from "./modules/single-news.min.js";
import { Contacts } from "./modules/contacts.min.js";

let appLenis = {};
let isPageLoad = true;
let swup = null;

// Initialize page
function init() {
  // Initialize Gsap + ScrollTrigger
  gsap.registerPlugin(ScrollTrigger);

  // Initialize Swup
  swup = new Swup({
    containers: ["#swup", "#site-navbar", ".footer"],
    cache: false,
  });

  // Init scroll monitor
  transitionCallback();

  // Set transition callback
  swup.hooks.on("content:replace", transitionCallback);

  // Reload iubenda iframes
  swup.hooks.on("page:view", function () {
    if ($(".iubenda-embed").length > 0) {
      location.reload();
    }
  });
}

const initLenis = () => {
  appLenis = {
    initialized: false,
    _lenis: null,

    init() {
      this._lenis = new Lenis({
        lerp: 0.085, // default value: 0.1

        // Prevent scrolling over model viewer                                                                                                                                               ▐
        prevent: (node) => node.classList.contains("model-viewer"),
      });

      this.initEvents();
      this.initialized = true;
    },

    initEvents() {
      this._lenis.on("scroll", ScrollTrigger.update);

      gsap.ticker.add((time) => {
        this._lenis.raf(time * 1000);
      });
    },

    destroy() {
      this._lenis.stop();
      this._lenis.destroy();
    },
  };

  appLenis.init();
};

const destroyAnimations = () => {
  const Alltrigger = ScrollTrigger.getAll();
  for (let i = 0; i < Alltrigger.length; i++) {
    Alltrigger[i].kill(true);
  }
};

const initPages = () => {
  new Header(isPageLoad, appLenis);
  new Home(isPageLoad);
  new About(isPageLoad);
  new Products(isPageLoad);
  new ProductPage(isPageLoad, swup);
  new News(isPageLoad);
  new SingleNews(isPageLoad);
  new Contacts(isPageLoad);
};

// Callback called on swup transition
function transitionCallback() {
  // Destroy prvious animations
  destroyAnimations();

  // Stop lenis to prevent scrolling during transitions
  if (appLenis.initialized) {
    appLenis.destroy();
  }

  // Initialize Lenis
  initLenis();

  // Initialize Pages
  initPages();

  // Initialize swipers
  new Swipers().init();

  // Reinitialize justified gallery
  new JustifiedGallery().init();

  // Reinitialize lazy load
  new LazyLoad().init();

  // Iubenda cleanup
  iubendaCheck();

  // Hide/Show reCaptcha badge based on page
  if ($("#contact-form").length > 0) {
    $(".grecaptcha-badge").addClass("visible");
  } else {
    $(".grecaptcha-badge").removeClass("visible");
  }
}

function iubendaCheck() {
  // Remove iubenda cookie message
  setTimeout(function () {
    if (_iub.cs.consent.purposes !== undefined) {
      let purposes_iub = Object.keys(_iub.cs.consent.purposes);

      for (let i = 0; i < purposes_iub.length; i++) {
        if (_iub.cs.consent.purposes[purposes_iub[i]] == true) {
          // Hide cookie message for enabled purposes
          $(".content-before-consent-" + purposes_iub[i]).each(
            function (_, el) {
              el.style.display = "none";
            },
          );

          // Send events to GTM
          switch (purposes_iub[i]) {
            case "2":
              // Attivatore Tag Manager categoria Interazioni Semplici
              dataLayer.push({
                event: "iubenda-interazioni-semplici",
              });
              break;
            case "3":
              // Attivatore Tag Manager categoria Esperienza Migliorata
              dataLayer.push({
                event: "iubenda-esperienza-migliorata",
              });
              break;
            case "4":
              // Attivatore Tag Manager categoria Misurazione
              dataLayer.push({ event: "iubenda-misurazione" });
              break;
            case "5":
              // Attivatore Tag Manager categoria Pubblicità
              dataLayer.push({ event: "iubenda-pubblicita" });
              break;
          }
        } else {
          // Show cookie message for disabled purposes
          $(".content-before-consent-" + purposes_iub[i]).each(
            function (_, el) {
              el.style.display = "flex";
            },
          );
        }
      }
    } else {
      // Show cookie message for disabled purposes
      $(".content-before-consent").each(function (_, el) {
        el.style.display = "block";
      });
    }
    _iub.cs.api.activateSnippets();
  }, 500);
}

function removePreloader() {
  isPageLoad = false;

  // Remove preloader
  const preloaderBg = document.querySelector("#preloader");
  const preloaderLogo = document.querySelector("#preloader img");
  const delay = 0.5;

  const tl = gsap.timeline();

  tl.to(preloaderLogo, {
    opacity: 0,
    y: "-8rem",
    duration: 1.2,
    delay: delay,
  }).to(preloaderBg, {
    opacity: 0,
    duration: 1,
    delay: -0.25,
    onComplete: () => {
      preloader.remove();
    },
  });
}

// Initialize page
$(window).on("load", function () {
  init();
  removePreloader();
});
